// Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase,
// DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper,
// SplitText, CustomBounce, CustomWiggle,
import { gsap, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);
let mm = gsap.matchMedia();

export default () => {
  //------------------------------------------------------//
  // Setup 🧦 GSAP and register 📜 ScrollTrigger
  // Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
  // Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
  //------------------------------------------------------//
  // gsap.registerPlugin(Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase, DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper, SplitText, CustomBounce, CustomWiggle);

  ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause",
    markers: window.location.hostname.endsWith(".local") ? true : false,
  });

  // END Setup 🧦 GSAP -------------------------------------//

  //------------------------------------------------------/ /
  // Animation random ornaments
  //------------------------------------------------------//
  let count = 0
  document.querySelectorAll(".has-ornaments").forEach(function (container) {
    // Create ornaments container
    const ornaments = document.createElement("ul");
    ornaments.classList.add("ornaments");
    container.appendChild(ornaments);

    // Setup
    const amount = gsap.utils.random(2, 2, 1);
    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;
    const items = [];

    for (let i = 0; i < amount; i++) {
      const item = document.createElement("li");
      count++;
      const random = {
        class: gsap.utils.wrap(["one", "two", "three", "four", "five", "six"], count),
        // size: gsap.utils.random(["small", "medium", "large", "small"]),
      };
      ornaments.appendChild(item);
      items.push(item);
      item.classList.add(random.class);
      // item.classList.add(random.size);

      let fromEdge = gsap.utils.random(-50, -100);
      mm.add("(max-width: 950px)", () => {
        fromEdge = gsap.utils.random(0, -20);
      });

      const randomPositionH = Math.floor(Math.random() * containerHeight);
      const randomPositionW = Math.floor(Math.random() * containerWidth);
      // Postion elments left or right
      if (i % 2) {
        item.style.top = randomPositionH + "px";
        item.style.left = fromEdge + "px";
      } else {
        item.style.top = randomPositionH + "px";
        item.style.right = fromEdge + "px";
      }
    }

    // GSAP  ScrollTrigger
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: container, // What element triggers the scroll
        scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
        start: "top bottom", // Can be top, center, bottom
      },
    });

    // console.warn(items);

    timeline.to(items, {
      // Get the max scroll height and get a random percentage from that value
      y: (index) =>
        -(container.offsetHeight / 2) *
        gsap.utils.wrap([1.2, 0.8, 0.4, 0.1], index),
      ease: "none",
    });
  });
  // END Animation random ornaments -------------------------------------//


  //--------------------------------//
  // ⭐️ Kenmerken 
  //--------------------------------//
  document.querySelectorAll(".ACF-kenmerken").forEach(function (container) {
    const q = gsap.utils.selector(container);
    const item = container.querySelectorAll(".item");

    let targets = q(".item");
    let numberOfTargets = targets.length

    let duration = 0.4 //change this
    let pause = 2 // change this

    let stagger = duration + pause
    let repeatDelay = (stagger * (numberOfTargets - 1)) + pause

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: container, // What element triggers the scroll
        scrub: false, // Add a small delay of scrolling and animation. `true` is direct
        start: "top bottom", // Can be top, center, bottom 
        end: "bottom top", // Can be top, center, bottom 
        // pin: true,
      }
    });
    function convertTextToNumber(text) {
      const numberMap = {
        one: 1,
        two: 2,
        three: 3,
        four: 4,
        five: 5,
        six: 6,
        seven: 7,
        eight: 8,
        nine: 9,
        ten: 10
      };

      // Convert the text to lowercase to ensure case insensitivity
      return numberMap[text.toLowerCase()] || null; // Return null if not found
    }
    function getSpecificClass(element, substring) {
      // Get all classes of the element
      const classes = element.classList;

      // Filter classes containing the specific substring
      const matchingClass = Array.from(classes).find(cls => cls.includes(substring));

      return matchingClass || null; // Return the matching class or null if none found
    }
    const colors = ['#51A2D3', '#F1C319', '#93C957', '#78C6AC', '#D63D4F', '#8B6CAE'];
    let playing = false;
    // animation
    timeline.from(targets, {
      y: 80,
      duration: duration,
      opacity: 0,
      stagger: {
        each: stagger,
        repeat: -1,
        onUpdate: function () {
          if (!playing) {
            playing = true;
            gsap.to(q('.alloy-shape'), {
              immediateRender: false,
              fill: colors[convertTextToNumber(getSpecificClass(this.targets()[0], 'alloy-background-').replace('alloy-background-', '')) - 1],
              onComplete: () => playing = false,
            })
          }
        },
        repeatDelay: repeatDelay
      }
    })
    timeline.to(targets, {
      y: -80,
      duration: duration,
      opacity: 0,
      stagger: {
        each: stagger,
        repeat: -1,
        repeatDelay: repeatDelay
      }
    }, stagger)
  });
  // END ⭐️ Kenmerken --------------//

  //------------------------------------------------------//
  // FAQ toggle 
  //------------------------------------------------------//
  document.querySelectorAll('.overview-faq').forEach(container => {
    const items = container.querySelectorAll('.alloy-item');
    items.forEach(item => {

      // The animation 
      const tl = gsap.timeline({ defaults: { duration: 0.3 }, reversed: true, paused: true })
      tl.set(item.querySelector('.hide'), { autoAlpha: 1 })

      tl.to(item.querySelector('.hide'), { height: "auto", })
      tl.to(item.querySelector('.icon'), { rotate: 180 }, "<")

      // The event listener
      item.addEventListener('click', e => {
        tl.reversed() ? tl.play() : tl.reverse();
      })
    });
  });
  // END FAQ toggle -------------------------------------//
};
